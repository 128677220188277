import React from 'react';
import PropTypes from 'prop-types';

import StandardCollection from '../../promo/collections/StandardCollection';
import { promoShape } from '../../promo/shapes';
import SeeAllRecipesLink from '../../generic/SeeAllRecipesLink';

const FeaturedFoodsTab = ({ foodsId, recipes, title, totalCount }) => (
  <div className="food-page__featured-foods-tab">
    <StandardCollection id={`recipes_${foodsId}`} promos={recipes} maxCollectionSize={24} />
    {totalCount > 24 && (
      <div className="see-all-recipes__wrap gel-wrap">
        <SeeAllRecipesLink
          className="gel-layout__item gel-1/1 gel-10/12@m"
          body={`all recipes using ${title}`}
          totalCount={totalCount}
          href={`/food/search?q=${title}`}
        />
      </div>
    )}
  </div>
);

FeaturedFoodsTab.propTypes = {
  foodsId: PropTypes.string.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
};

FeaturedFoodsTab.defaultProps = {
  recipes: [],
  totalCount: 0,
};

export default FeaturedFoodsTab;
