import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import upperFirst from 'lodash/upperFirst';

import AzKeyboard from '../../generic/aToZ/AzKeyboard';
import Pagination from '../../generic/pagination/Pagination';
import PaginationSummary from '../../generic/pagination/PaginationSummary';
import StandardCollection12Promos from '../../promo/collections/StandardCollection12Promos';
import { promoShape } from '../../promo/shapes';
import MetaProperties from '../../generic/MetaProperties';

const AllFoodsTab = ({
  anchor,
  azCounts,
  baseURL,
  foodsId,
  promos,
  title,
  letter,
  page,
  pages,
}) => (
  <>
    <MetaProperties
      title={`${upperFirst(title)} recipes | ${letter.toUpperCase()}`}
      description={`Find your favourite recipes on BBC Food. This page is an alphabetical listing of ${title} recipes that start with ${letter}.`}
      canonical={`${baseURL}/food/${foodsId}`}
    />
    <Helmet>
      {page > 1 && (
        <link rel="prev" href={`${baseURL}/food/${foodsId}/a-z/${letter}/${page - 1}`} />
      )}
      {page < pages && (
        <link rel="next" href={`${baseURL}/food/${foodsId}/a-z/${letter}/${page + 1}`} />
      )}
    </Helmet>
    <AzKeyboard
      anchor={anchor}
      baseUrl={`/food/${foodsId}`}
      selected={letter}
      azCounts={azCounts}
    />
    <PaginationSummary page={page} totalCount={azCounts[letter]}>
      {title} recipes
    </PaginationSummary>
    {promos && (
      <StandardCollection12Promos
        id={`az-${foodsId}-${letter}-recipes`}
        promos={promos}
        maxCollectionSize={24}
      />
    )}
    <Pagination
      page={page}
      pages={Math.ceil(azCounts[letter] / 24)}
      buildUrl={p => `/food/${foodsId}/a-z/${letter}/${p}#${anchor}`}
    />
  </>
);

AllFoodsTab.propTypes = {
  anchor: PropTypes.string,
  azCounts: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  baseURL: PropTypes.string.isRequired,
  foodsId: PropTypes.string.isRequired,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};

AllFoodsTab.defaultProps = {
  anchor: '',
  promos: [],
};

export default AllFoodsTab;
