import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import upperFirst from 'lodash/upperFirst';
import Hero from '../promo/Hero';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import PageHeader, { Header2 } from '../generic/PageHeader';
import Paragraphs from '../generic/Paragraphs';
import ContentSeparator from '../generic/ContentSeparator';
import TabBar from '../generic/tabBar/TabBar';
import Tab from '../generic/tabBar/Tab';
import { CollectionTitle } from '../promo/Collection';
import { promoShape } from '../promo/shapes';
import AllFoodsTab from './all/AllFoodsTab';
import FeaturedFoodsTab from './featured/FeaturedFoodsTab';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const TextSection = ({ text, title }) => (
  <div className="text-section gel-wrap">
    <div className="gel-layout">
      <div className="text-section__title gel-layout__item">
        <CollectionTitle className="text-section__title-text" TitleElement="h2">
          {title}
        </CollectionTitle>
      </div>
      <div className="text-section__text gel-layout__item gel-8/10@m">
        <Paragraphs text={text} className="text-section__description" />
      </div>
    </div>
  </div>
);

TextSection.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const anchor = 'featured-content';

const Page = ({
  baseURL,
  azCounts,
  buyersGuide,
  description,
  hero,
  foodsId,
  otherConsiderations,
  preparation,
  recipes,
  relatedCollections,
  storage,
  title,
  totalCount,
  ogImage,
  letter,
  page,
  pages,
  match,
}) => (
  <>
    <MetaProperties
      title={`${upperFirst(title)} recipes`}
      description={description}
      imageUrl={ogImage}
    />
    <PageHeader
      title={`${upperFirst(title)} recipes`}
      description={
        description && <Paragraphs text={description} className="text-section__description" />
      }
      anchor={hero ? 'page-title' : anchor}
    />
    {hero && (
      <>
        <Hero className="food-page__hero" {...hero} />
        <Header2 title={`More ${title} recipes`} anchor={anchor} />
      </>
    )}
    {totalCount > 24 && (
      <TabBar>
        <Route exact path={match.path}>
          {({ match: selected }) => (
            <Tab
              href={`/food/${foodsId}#${anchor}`}
              title="Show featured recipes"
              selected={!!selected}
            >
              Featured
            </Tab>
          )}
        </Route>
        <Route exact path={`${match.path}/a-z/:letter/:page`}>
          {({ match: selected }) => (
            <Tab
              href={`/food/${foodsId}/a-z#${anchor}`}
              title="Show all recipes"
              selected={!!selected}
            >
              All
            </Tab>
          )}
        </Route>
      </TabBar>
    )}
    <Route
      exact
      path={match.path}
      render={() => (
        <FeaturedFoodsTab
          anchor={anchor}
          foodsId={foodsId}
          recipes={recipes}
          title={title}
          totalCount={totalCount}
        />
      )}
    />
    <Route
      exact
      path={`${match.path}/a-z/:letter/:page`}
      render={() => (
        <AllFoodsTab
          anchor={anchor}
          azCounts={azCounts}
          baseURL={baseURL}
          foodsId={foodsId}
          promos={recipes}
          title={title}
          letter={letter}
          page={page}
          pages={pages}
        />
      )}
    />
    {buyersGuide && <TextSection title="Buyer's guide" text={buyersGuide} />}
    {storage && <TextSection title="Storage" text={storage} />}
    {preparation && <TextSection title="Preparation" text={preparation} />}
    {otherConsiderations && <TextSection title="Other considerations" text={otherConsiderations} />}
    {relatedCollections.length > 0 && (
      <div className="food-body__grey-background">
        {relatedCollections.map(collection => (
          <Fragment key={collection.id}>
            <StandardCollection6Promos
              id={collection.id}
              title={collection.title}
              promos={collection.promos}
              maxCollectionSize={collection.promos.length}
            />
            <ContentSeparator />
          </Fragment>
        ))}
      </div>
    )}
  </>
);

Page.propTypes = {
  baseURL: PropTypes.string.isRequired,
  buyersGuide: PropTypes.string,
  description: PropTypes.string,
  hero: PropTypes.shape(promoShape),
  foodsId: PropTypes.string,
  otherConsiderations: PropTypes.string,
  preparation: PropTypes.string,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  totalCount: PropTypes.number,
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relatedCollections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
    })
  ),
  storage: PropTypes.string,
  title: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  letter: PropTypes.string,
  page: PropTypes.number,
  pages: PropTypes.number,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

Page.defaultProps = {
  buyersGuide: undefined,
  description: undefined,
  hero: undefined,
  foodsId: undefined,
  otherConsiderations: undefined,
  preparation: undefined,
  recipes: [],
  totalCount: 0,
  azCounts: {},
  relatedCollections: [],
  storage: undefined,
  ogImage: '',
  letter: 'a',
  page: 1,
  pages: 1,
};

const ConnectedPage = connect(state => ({
  baseURL: state.pageReducer.baseURL,
  ...pick(state.foodsReducer, [
    'buyersGuide',
    'description',
    'foodsId',
    'hero',
    'otherConsiderations',
    'preparation',
    'recipes',
    'totalCount',
    'azCounts',
    'relatedCollections',
    'storage',
    'title',
    'ogImage',
    'letter',
    'page',
    'pages',
  ]),
}))(Page);

export { ConnectedPage as default, TextSection, Page };
